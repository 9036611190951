import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsletterCta from "../components/newsletter-cta"
import SearchResult from "../components/search-result"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import Box from "@material-ui/core/Box"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, PoweredBy, Configure } from "react-instantsearch-dom"
import CircularProgress from "@material-ui/core/CircularProgress"
import qs from "qs"

import {
  connectHits,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom"

const useStyles = makeStyles((theme) => ({
  hitsContainer: {
    "ul &": {
      marginLeft: 0,
    },
  },
}))

const SearchBox = ({ refine, isSearchStalled, currentRefinement }) => {
  function queryValue() {
    return document.querySelector(".MuiInputBase-inputTypeSearch").value
  }

  function handleSubmit(event) {
    refine(queryValue())
  }

  function handleReturn(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      refine(queryValue())
    }
  }

  return (
    <>
      <TextField
        label="Search"
        type="search"
        defaultValue={currentRefinement}
        margin="normal"
        variant="outlined"
        fullWidth
        onKeyPress={handleReturn}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSubmit} onMouseDown={handleSubmit}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isSearchStalled && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "50px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}

const RecipesPage = ({ location }) => {
  const [defaultRefinement, setDefaultRefinement] = useState(``)
  const classes = useStyles()

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const QueryText = connectStateResults(({ searchResults }) => {
    const hitCount = searchResults && searchResults.nbHits
    const query = searchResults && searchResults.query

    if (!query) return <></>

    return hitCount > 0 ? (
      <Typography variant="overline" display="block" gutterBottom>
        Showing results for "{query}" ({hitCount})
      </Typography>
    ) : (
      <Typography component="p" display="block" gutterBottom>
        Unfortunately, we don't have any recipes matching "{query}" yet. But
        stay tuned, there may be a matching recipe coming soon!
      </Typography>
    )
  })

  const Hits = ({ hits }) =>
    hits.map((hit) => (
      <Grid key={hit.objectID} item xs={12}>
        <SearchResult hit={hit} />
      </Grid>
    ))

  const CustomHits = connectHits(Hits)

  const CustomSearchBox = connectSearchBox(SearchBox)

  useEffect(() => {
    const params = qs.parse(location.search.slice(1))
    if (
      (params.query && params.query.length) ||
      (params.keywords && params.keywords.length)
    ) {
      setDefaultRefinement(params.query || params.keywords)
    }
  }, [location.search])

  return (
    <Layout>
      <Seo title="Recipes" />

      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom>
          All Recipes
        </Typography>

        <InstantSearch indexName="dev_dessertisans" searchClient={searchClient}>
          <Box pb={2}>
            <CustomSearchBox defaultRefinement={defaultRefinement} />
          </Box>
          <Container maxWidth="sm" className={classes.hitsContainer}>
            <QueryText />
            <Grid container spacing={2}>
              <CustomHits />
            </Grid>
            <Box pt={2}>
              <PoweredBy />
            </Box>
          </Container>
          <Configure hitsPerPage={100} />
        </InstantSearch>
      </Container>

      <Container maxWidth="md">
        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </Container>

      <Container maxWidth="md">
        <NewsletterCta />
      </Container>
    </Layout>
  )
}

export default RecipesPage

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Box from "@material-ui/core/Box"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import truncate from "lodash/truncate"

const useStyles = makeStyles((theme) => ({
  searchResultCard: {
    height: "100%",
    border: "1px solid #ecebea",
    boxShadow: "1px 3px 2px rgba(0, 0, 0, 0.05)",
    transition: "all 0.4s ease 0s",
    "&:hover": {
      transform: "translateY(-5px) scale3d(1.01, 1.01, 1.01)",
    },
  },
  searchResultHeading: {
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: 0,
    fontSize: "1.15rem",
  },
}))

const SearchResult = ({ hit }) => {
  const classes = useStyles()

  return (
    <Card className={[classes.searchResultCard].join(" ")}>
      <CardActionArea href={hit.slug}>
        {hit.thumbnailImage && (
          <CardMedia>
            <GatsbyImage
              alt={hit.thumbnailImage.title}
              image={hit.thumbnailImage.gatsbyImageData}
              loading={"lazy"}
            />
          </CardMedia>
        )}

        <CardContent>
          <Typography variant="h3" className={classes.searchResultHeading}>
            {hit.title}
          </Typography>

          <Typography variant="caption" gutterBottom>
            {hit.createdAt}
          </Typography>

          <Box mt={2}>
            <Typography variant="body1">
              {truncate(hit.abstract, { length: 160, separator: /,? +/ })}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SearchResult
